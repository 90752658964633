!function(window, document) {

    'use strict';

    class GlobalAdManager {
        /**
         * @param {string} sdgZone
         * @param {{loadTransitionAds: boolean}?} options
         */
        initSDG (sdgZone, options) {
            console.log('%cInitialising SDG...', 'background: #a9007a; color: white;');
            SDG.Publisher.setZone(sdgZone);
            if (options?.loadTransitionAds === true) {
                SDG.Publisher.transitionAdvertisements();
            }
            this._loadAllSlots();
            SDG.Publisher.finalizeSlots();

            console.log('%cSDG initialized.', 'background: #a9007a; color: white;');
            this._logPerformanceMarker();
        }

        /**
         * @param {string} sdgZone
         * @param {{loadTransitionAds: boolean}?} options
         */
        initSDGAsync (sdgZone, options) {
            console.log('%cInitialising SDG...', 'background: #a9007a; color: white;');
            window.SDG = window.SDG || {};
            window.SDG.cmd = window.SDG.cmd || [];
            window.SDG.cmd.push(() => SDG.Publisher.setZone(sdgZone));
            if (options?.loadTransitionAds === true) {
                window.SDG.cmd.push(() => SDG.Publisher.transitionAdvertisements());
            }
            this._loadAllSlots(true);
            window.SDG.cmd.push(() => SDG.Publisher.finalizeSlots());

            console.log('%cSDG initialized.', 'background: #a9007a; color: white;');
            window.SDG.cmd.push(() => this._logPerformanceMarker());
        }

        _loadAllSlots (register_async = false) {
            document.querySelectorAll('[data-ads-provider=sdg]').forEach((node) => {
                const slotName = node.dataset.adsSlot;
                this._setContainerId(slotName, node);
                if (register_async) {
                    console.log('%cSLOTMANAGER: ', 'background: #a9007a; color: white;', 'found', slotName);
                    window.SDG.cmd.push(() => this._loadSlot(slotName, node));
                } else {
                    this._loadSlot(slotName, node);
                }
             });
        }

        _loadSlot(slotName, node) {
            const sdgSlot = SDG.Publisher.registerSlot(slotName, node);
            const loggingData = this._configureSlot(sdgSlot, node);

            if (node.dataset.adsViewportLoad === 'false') {  // false is the default case; true is special here
                sdgSlot.load();
                console.log('%cSLOTMANAGER: ', 'background: #a9007a; color: white;', 'load', slotName, loggingData ?? '');
            } else {
                this._customSlotLogic(slotName, sdgSlot, node)
                console.log('%cSLOTMANAGER: ', 'background: #a9007a; color: white;', 'custom load', slotName, loggingData ?? '');
            }
        }

        _configureSlot(sdgSlot, node) {
            const loggingData = [];
            if (node.dataset?.adsSetSticky === 'true') {
                sdgSlot.configure({pinToBottom: true});
                loggingData.push('sticky');
            }
            const reserveSpace = node.dataset?.adsReserveSpace ?? 'false';
            if (reserveSpace === 'true' || /^[0-9]+$/.test(reserveSpace)) {
                // NOTE: if either literal 'true' or a number (of pixels)
                sdgSlot.configure({
                    showLoadingAnimation: true,
                    reserveSpace: true,
                });
                loggingData.push('reserve');
                if (reserveSpace !== 'true') {
                    // i.e., it's a number in string format
                    sdgSlot.configure({
                        fixedHeight: +reserveSpace,
                    });
                }
            }
            return loggingData.length ? loggingData : null;
        }

        _customSlotLogic(slotName, sdgSlot, node) { }

        _setContainerId (slotName, node) {
            if (!node.id) {
                const idName = 'id_sdg_slot_' + slotName;
                const idCount = document.querySelectorAll(`*[id^=${idName}]`).length;
                node.id = idCount === 0 ? idName : `${idName}_${idCount + 1}`;
            }
        }

        _logPerformanceMarker() {
            try {
                performance.mark('sso-metatag-init-done');
                const metatag = this._measure('sso-pre-scripts', 'sso-metatag-init-done', 'sso-m-metatag');
                const sp_user = this._measure('sso-pre-scripts', 'sso-sp-done-user', 'sso-m-sp-user');
                const sp_loaded = this._measure('sso-pre-scripts', 'sso-sp-done-loaded', 'sso-m-sp-loaded');
                const cp = this._measure('sso-pre-scripts', 'sso-cp-done', 'sso-m-cp');
                const measurements = [metatag, sp_loaded, sp_user, cp];
                sso_analytics.proxyEvent({
                    eventCategory: 'adstack',
                    eventAction: 'performance',
                    eventLabel: measurements.map((m) => Math.round(m)).join(','),
                    nonInteraction: true,
                });
                console.log('%cMEASUREMENT: ', 'background: #4caf50; color: white;', measurements.map((m) => Math.round(m)).join(','));
            } catch (e) {}
        }

        /**
         * Measures between two performance markers. Returns zero duration if marker doesn't exist.
         * @param fromMark
         * @param toMark
         * @param measurementName
         * @return {number}
         * @private
         */
        _measure(fromMark, toMark, measurementName) {
            return performance.getEntriesByName(fromMark, 'mark').length > 0 && performance.getEntriesByName(toMark, 'mark').length > 0
                    ? performance.measure(measurementName, fromMark, toMark).duration
                    : 0;
        }

        static getAllSlotContainers() {
            return document.querySelectorAll('[data-ads-provider=sdg]');
        }

        static initAmazonPreBidSystem () {
            (function (a9, a, p, s, t, A, g) {
                if (a[a9]) return; function q(c, r) { a[a9]._Q.push([c, r]) }a[a9] = {init: function () { q('i', arguments) }, fetchBids: function () { q('f', arguments) }, setDisplayBids: function () {}, targetingKeys: function () { return [] }, _Q: []}; A = p.createElement(s); A.async = !0; A.src = t; g = p.getElementsByTagName(s)[0]; g.parentNode.insertBefore(A, g)
            }('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js'));
        }
    }

    window.sso.GlobalAdManager = GlobalAdManager;
}(window, document);
